<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('privacy.title') }}
      </template>
    </RMPageHeader>
    <div class="row justify-content-center">
      <h3>{{ $t('privacy.common') }}</h3>
    </div>
    <div class="row justify-content-center about-info">
      <div class="col-12 m-l-5 m-r-5 m-t-10">
        <p v-html="$t('privacy.p1').replaceAll('{pageUrl}', this.pageURL)"></p>
        <p v-html="$t('privacy.p2').replaceAll('{pageUrl}', this.pageURL)"></p>
        <p v-html="$t('privacy.p3').replaceAll('{pageUrl}', this.pageURL)"></p>
        <p v-html="$t('privacy.p4').replaceAll('{pageUrl}', this.pageURL)"></p>
        <p v-html="$t('privacy.p5').replaceAll('{pageUrl}', this.pageURL)"></p>
        <h4>{{ $t('privacy.definitions') }}</h4>
        <p></p>
        <p v-html="$t('privacy.d1').replaceAll('{pageUrl}', this.pageURL)"></p>
        <p v-html="$t('privacy.d2')"></p>
        <p v-html="$t('privacy.d3').replaceAll('{pageUrl}', this.pageURL)"></p>
        <p v-html="$t('privacy.d4')"></p>
        <p v-html="$t('privacy.d5')"></p>
        <p v-html="$t('privacy.d6')"></p>
        <p v-html="$t('privacy.d7')"></p>
        <h4>{{ $t('privacy.intelectualProperty') }}</h4>
        <p></p>
        <p v-html="$t('privacy.i1')"></p>
        <p v-html="$t('privacy.i2').replaceAll('{pageUrl}', this.pageURL)"></p>
        <h4>{{ $t('privacy.login') }}</h4>
        <p></p>
        <p v-html="$t('privacy.l1').replaceAll('{pageUrl}', this.pageURL)"></p>
        <p v-html="$t('privacy.l2').replaceAll('{pageUrl}', this.pageURL)"></p>
        <p v-html="$t('privacy.l3').replaceAll('{pageUrl}', this.pageURL)"></p>
        <p v-html="$t('privacy.l4')"></p>
        <h4>{{ $t('privacy.protection') }}</h4>
        <p></p>
        <p v-html="$t('privacy.pr1')"></p>
        <p v-html="$t('privacy.pr2')"></p>
        <p v-html="$t('privacy.pr3')"></p>
        <p v-html="$t('privacy.pr3_1')"></p>
        <p v-html="$t('privacy.pr4')"></p>
        <p v-html="$t('privacy.pr5')"></p>
        <p v-html="$t('privacy.pr6')"></p>
        <p v-html="$t('privacy.pr7').replaceAll('{pageUrl}', this.pageURL)"></p>
      </div>
    </div>
    <div class="row justify-content-center">
      <h3>{{ $t('privacy.cookies') }}</h3>
    </div>
    <div class="row justify-content-center about-info">
      <div class="col-12 m-l-5 m-r-5 m-t-10">
        <p v-html="$t('privacy.c1').replaceAll('{pageUrl}', this.pageURL)"></p>
        <p v-html="$t('privacy.c2')"></p>
        <p v-html="$t('privacy.c3')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import {getDomain} from "@/_helpers/domain_helper";
import RMPageHeader from "@/components/common/RMPageHeader.vue";

export default {
  name: "Privacy",
  components: {RMPageHeader},
  data() {
    return {
      pageURL: ""
    }
  },
  methods: {
    setPageURL() {
      this.pageURL = getDomain()
    }
  },
  created() {
    this.setPageURL();
  }
}
</script>

<style scoped>

</style>
